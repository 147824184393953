import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import "./style.css";
import { useNavigate } from "react-router-dom";

const MapPopup = ({ google, vendors }) => {
  const [mapCenter, setMapCenter] = useState({
    lat: vendors[0].latitude,
    lng: vendors[0].longitude,
  });
  const [mapZoom, setMapZoom] = useState(7);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [markerName, setMarkerName] = useState("");
  const [vendorId, setVendorId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (vendors.length > 0) {
      const randomVendor = vendors[Math.floor(Math.random() * vendors.length)];
      setMapCenter({ lat: randomVendor.latitude, lng: randomVendor.longitude });
      setMapZoom(9);
    }
  }, [vendors]);

  const handleMarkerClick = (props, marker, id) => {
    setActiveMarker(marker);
    setModalOpen(true);
    setMarkerName(marker.name);
    setVendorId(id);
  };

  const handleModalClose = () => {
    setActiveMarker(null);
    setModalOpen(false);
  };

  const mapStyles = {
    width: "100%",
    height: "100%",
  };
  const viewOffer = () => {
    navigate("/offers", {
      state: { id: vendorId },
    });
  };

  const onInfoWindowOpen = () => {
    const button = (
      <div
        className="btn"
        onClick={() => {
          viewOffer();
        }}
      >
        View
      </div>
    );
    ReactDOM.render(
      React.Children.only(button),
      document.getElementById("offer")
    );
  };
  return (
    <Map
      google={google}
      zoom={mapZoom}
      initialCenter={mapCenter}
      style={mapStyles}
    >
      {vendors.map((vendor) => (
        <Marker
          onClick={(props, marker) =>
            handleMarkerClick(props, marker, vendor.id)
          }
          key={vendor.id}
          name={vendor.name}
          position={{ lat: vendor.latitude, lng: vendor.longitude }}
        />
      ))}
      <InfoWindow
        marker={activeMarker}
        visible={isModalOpen}
        onClose={handleModalClose}
        onOpen={(e) => {
          onInfoWindowOpen(e);
        }}
      >
        <div className="info-window-content" onClick={viewOffer}>
          <div>
            <div>{markerName}</div>
            <div className="left">
              <div id="offer"></div>
            </div>
          </div>
        </div>
      </InfoWindow>
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBVR5TmltU5pq9QiR8s7v8Wc3SnTcj8Gys",
})(MapPopup);
