import Header from "../../components/HomeComponents/Header";
import Heart from "../../images/redHeart.svg";
import arrowRight from "../../images/arrowRight.svg";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";
import Footer from "../../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { SessionContext } from "../../components/UserContext";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { motion } from 'framer-motion';

function Favorites() {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState([]);

  const { state, updateState } = useContext(SessionContext);

  useEffect(() => {
    if (state.favorites.length > 0) {
      setData(state.favorites);
    }

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${state.token}`,
    };
    axios
      .get("user/favorite", { headers: headers })
      .then((response) => {
        updateState({ favorites: response.data.data });
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);
  function handleHeartClick(id) {
    const heads = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${state.token}`,
    };
    const body = {
      vendor_id: id,
    };
    const url = "user/favorite/remove";
    axios
      .post(url, body, {
        headers: heads,
      })
      .then((response) => {
        if (response.data.status == true) {
          const index = data.findIndex((item) => item.id === id);
          //console.log("removing", index);
          if (index > -1) {
            var tmp = JSON.parse(JSON.stringify(data));
            tmp.splice(index, 1);
            setData(tmp);
          }

          toast.success("Removed from favorites!");
        }
      })
      .catch((error) => {
        toast.error("There was an error!", {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error("There was an error!", error);
      });
  }
  return (
    <motion.div
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <div
        style={{
          width: width < 800 ? "100%" : "80%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header showback heading={"Favorites"} phone heart />
        <div style={{ height: "100%", width: "90%", alignSelf: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: 10,
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {data.map((item) => (
              <div
                className="FavoriteCard"
                style={{
                  padding: 10,
                  width: "100%",
                  height: "60px",
                  borderRadius: "10px",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div
                // className="vendorCard"
                // style={{
                //   width: "70px",
                //   textAlign: "center",
                //   height: "100%",
                //   borderRadius: "20px 10px 10px 20px",
                // }}
                >
                  <img
                    src={item.logo}
                    style={{
                      paddingTop: "5px",
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      marginLeft: 20,
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "80%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingLeft: "10px",
                  }}
                >
                  <h5 style={{ margin: 0, fontSize: 16, color: "#333333" }}>
                    {item.name}
                  </h5>
                  <p style={{ margin: 0, fontSize: 12, color: "#686C6E" }}>
                    {item.locality}
                  </p>
                </div>
                <div
                  style={{
                    width: "20%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    paddingRight: "10px",
                  }}
                >
                  <img
                    src={Heart}
                    style={{ display: "none", width: "30px" }}
                    onClick={() => handleHeartClick(item.id)}
                  />
                  <img
                    src={arrowRight}
                    style={{ width: "20px" }}
                    onClick={() =>
                      navigate("/offers", {
                        state: { id: item.id },
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </motion.div>
  );
}

export default Favorites;
