import "./index.css";
import Header from "../../components/HomeComponents/Header";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";
import Footer from "../../components/Footer";
import arrowRight from "../../images/arrowRight.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { SessionContext } from "../../components/UserContext";
import { motion } from "framer-motion"

function Shop() {
  const { width, height } = useWindowDimensions();
  return (
    <motion.div
      initial={{ y: 300, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <div
        style={{
          width: width < 800 ? "100%" : "80%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Header showback heading={"Shop"} phone heart noti profile />
        <div style={{ height: "90vh", width: "90%", alignSelf: "center" }}>
          <iframe
            src="https://vouch365.com/shop/"
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        </div>
      </div>
    </motion.div>
  );
}

export default Shop;
