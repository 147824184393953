import Header from "../../components/HomeComponents/Header";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Rating } from "react-simple-star-rating";
import Location from "../../images/location.svg";
import FoodAndDrink from "../../images/foodAndDrink.svg";
import Heart from "../../images/headerHeart.svg";
import Check from "../../images/check.png";
import RedHeart from "../../images/redHeart.svg";
import Info from "../../images/info.svg";
import ArrowRight from "../../images/arrowRight.svg";
import dropdown from "../../images/dropdown.svg";
import Progress_bar from "../../components/proressBar";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
import axios from "../../utils/axios";
import { SessionContext } from "../../components/UserContext";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import AuthCode from "react-auth-code-input";
import { motion } from "framer-motion"
import { BottomSheet } from 'react-spring-bottom-sheet'
function Offers() {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const [redemptionTab, setRedemptionTab] = useState(1);
  const [refNum, setRefNum] = useState("");
  const location = useLocation();
  const [showRedeem, setShowRedeem] = useState(false);
  const [showBranches, setShowBranches] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState({});
  const [data, setData] = useState({});
  const [isFave, setFave] = useState(false);
  const { state } = useContext(SessionContext);
  const inputs = useRef([]);

  const myStyle = {
    height: "80vh",
    width: "80vw",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
  };
  function fetchData(id = undefined) {
    var vendor = "/vendor/" + (id != undefined ? id : location.state.id);
    console.log(vendor);
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${state.token}`,
    };

    axios
      .get(vendor, {
        headers: headers,
      })
      .then((response) => {
        var ratings = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
        for (var i = 0; i < response.data.data.reviews.length; i++) {
          ratings[response.data.data.reviews[i].rating]++;
        }
        response.data.data.ratings = ratings;
        console.log(response.data.data.ratings);

        if (id != undefined) {
          var locations = data.locations;
          response.data.data.locations = locations;
          setData(response.data.data);
        } else setData(response.data.data);

        if (id == undefined && response.data.data.locations.length > 1) {
          setTimeout(() => {

            setShowBranches(true);
          }, 1000);
        }
        if (response.data.data.favorite == true) {
          setFave(true);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  function handleHeartClick(e) {
    e.preventDefault();
    const heads = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${state.token}`,
    };
    const body = {
      vendor_id: location.state.id,
    };
    const url = isFave ? "user/favorite/remove" : "user/favorite/add";
    axios
      .post(url, body, {
        headers: heads,
      })
      .then((response) => {
        if (response.data.status == true) {
          toast.success(
            !isFave ? "Added to favorites!" : "Removed from favorites!"
          );
          setFave(!isFave);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function showVoucher(voucher) {
    if (!state.loggedIn) {
      toast.error("Please login to redeem voucher!");
      return;
    }
    /*if (!voucher.eligiblity) {
            toast.error("This voucher is not valid in your current membership, please upgrade!");
            return;
        } */
    setSelectedVoucher(voucher);
    setRedemptionTab(1);
    setShowRedeem(true);
  }
  function redeemVoucher(pinInput) {
    const heads = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${state.token}`,
    };
    const url = "redemption";
    const dt = {
      premium_key_id: selectedVoucher.premium_key_id,
      voucher_id: selectedVoucher.id,
      pin: pinInput,
      vendor_id: data.id,
    };
    axios
      .post(url, dt, {
        headers: heads,
      })
      .then((response) => {
        if (response.data.status === true) {
          setRefNum(response.data.data.reference);
          //setShowRedeem(false);
          setRedemptionTab(3);
          fetchData(data.id);
          toast.success("You have successfully redeemed a voucher!");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error("Invalid PIN entered!");
        //toast.error("Error! " + error);
        //console.error('There was an error!', error);
      });
  }
  const options = {
    title: "Confirmation",
    message: "Do you want to redeem this voucher\n No pin will be required",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          redeemVoucher(data.pin);
        },
      },
      {
        label: "No",
        onClick: () => null,
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => { },
    afterClose: () => { },
    onClickOutside: () => { },
    onKeypress: () => { },
    onKeypressEscape: () => { },
    overlayClassName: "overlay-custom-class-name",
  };
  const handleChange = (e, index) => {
    const { value } = e.target;
    if (value.length > 1) {
      e.target.value = value.slice(0, 1); // Take only the first character
    }

    if (value.length === 1 && index < inputs.current.length - 1) {
      inputs.current[index + 1].focus();
    }
    let otpValue = inputs.current.map((m) => {
      return m.value;
    });
    otpValue = otpValue.join("");
    if (otpValue.length === 4) {
      redeemVoucher(otpValue);
    }
  };

  const handleKeyUp = (e, index) => {
    const { keyCode } = e;
    if (keyCode === 8 && index > 0) {
      inputs.current[index - 1].focus();
    }
  };
  const Heading = ({ tab, setTab }) => {
    return (
      <div
        className="headingsTab"
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          // height: "30px",
          marginTop: 10,
          width: width < 800 ? '70%' : '50%',
          alignSelf: 'center'
        }}
      >
        <div
          onClick={() => setTab(1)}
          style={{
            backgroundColor: tab === 1 ? "black" : "",
            margin: "0 20px",
            width: "100%",
            fontSize: "12px",
            fontWeight: "bold",
            margin: 10,
            border: "1px solid black",
            borderRadius: 5,
            textAlign: "center",
            color: tab === 1 ? "white" : "black",
          }}
        >
          <p>Offer</p>
        </div>
        <div
          onClick={() => setTab(2)}
          style={{
            backgroundColor: tab === 2 ? "black" : "",
            margin: "0 20px",
            width: "100%",
            fontSize: "12px",
            fontWeight: "bold",
            margin: 10,
            border: "1px solid black",
            borderRadius: 5,
            textAlign: "center",
            color: tab === 2 ? "white" : "black",
          }}
        >
          <p>Details</p>
        </div>
        <div
          onClick={() => setTab(3)}
          style={{
            backgroundColor: tab === 3 ? "black" : "",
            margin: "0 20px",
            width: "100%",
            fontSize: "12px",
            fontWeight: "bold",
            margin: 10,
            border: "1px solid black",
            borderRadius: 5,
            textAlign: "center",
            color: tab === 3 ? "white" : "black",
          }}
        >
          <p>Reviews</p>
        </div>
      </div>
    );
  };

  const OfferList = ({ item }) => {
    if (Object.keys(item).length == 0) {
      console.log("Empty");
      return null;
    }
    return (
      <div className='offerList'>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "50px",
          }}
        >
          <div style={{ alignItems: "center", display: "flex" }}>
            <Rating size={18} iconsCount={1} initialValue={1} readonly={true} />
            <span
              style={{
                fontSize: "15px",
                marginLeft: "5px",
                fontWeight: "bold",
              }}
            >
              {item.name}
            </span>
          </div>
          <div
            style={{ alignItems: "center", display: "flex" }}
            onClick={() => setShowBranches(!showBranches)}
          >
            <img src={Location} alt="" style={{ width: "20px" }} />
            <span
              style={{ fontSize: "15px", margin: "0 5px", fontWeight: "bold" }}
            >
              {item.locality}
            </span>
          </div>
        </div>
        {item.vouchers.voucherData.map((itm, i) => (
          <div
            key={i}
            className={
              itm.redeemed == 1 ? "voucherItem redeemed" : "voucherItem"
            }
            style={{ display: "flex" }}
            onClick={() =>
              itm.redeemed == 0
                ? showVoucher(itm)
                : toast.error("You have already redeemed this voucher!")
            }
          >
            <div style={{ display: "grid" }}>
              <div
                style={{
                  height: "50px",
                  backgroundColor: "#fff",
                  width: "50px",
                  borderRadius: "25px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19)",
                }}
              >
                <img
                  src={itm.redeemed == 1 ? Check : FoodAndDrink}
                  alt=""
                  style={{ width: "28px" }}
                />
              </div>
              {i <= item.vouchers.voucherData.length - 2 && (
                <span className="verticalDivider" />
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginLeft: "15px",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat Bold",
                  marginBottom: "5px",
                }}
              >
                {itm.name}
              </span>
              <span
                style={{ fontSize: "12px", fontWeight: "600", color: "#888" }}
              >
                Expires: {new Date(itm.exp_date).toDateString()}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handlePinInput = (res) => {
    if (res.length < 4) return;
    else redeemVoucher(res);
  };

  const BranchPopup = ({ item }) => {
    try {
      return (
        <div
          style={{
            display: "block",
            position: "fixed",
            width: width > 800 ? '73%' : '100%',
            zIndex: "9999",
            height: "100vh",
            backgroundColor: "#00000099",
            opacity: !showBranches ? "0" : "1",
            transition: "all .2s",
            visibility: !showBranches ? "hidden" : "visible",
          }}
        >
          <div
            style={{
              display: showBranches ? "block" : "none",
              justifyContent: "center",
              position: "fixed",
              bottom: 0,
              height: "65vh",
              width: width > 800 ? '73%' : '100%',
              background: "#fdfdfd",
              borderRadius: "30px 30px 0 0",
              boxShadow: "0px -5px 20px 10px #59595936",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <h4>{item.name}</h4>
              <h4>{item.locations.length} Locations</h4>
            </div>
            <div>
              {item.locations.map((itm, i) => (
                <div
                  key={i}
                  onClick={() => {
                    fetchData(itm.id);
                    setShowBranches(false);
                  }}
                  style={{
                    display: "flex",
                    padding: "0px",
                    alignItems: "center",
                    borderTop: "1px solid #3f3f3f9e",
                  }}
                >
                  <img
                    src={Location}
                    alt=""
                    style={{
                      width: "22px",
                      height: "22px",
                      margin: "0px 10px",
                      background: "white",
                      boxShadow: "1px 1px 13px 0px #ababab",
                      borderRadius: "50%",
                      padding: "6px",
                    }}
                  />
                  <p style={{ margin: "15px 0" }}>{itm.locality}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    } catch (e) { }
    return <br />;
  };

  // const RedemptionPopup = ({ item }) => {
  //   return (
  //     // <div
  //     //   style={{
  //     //     display: "flex",
  //     //     position: "fixed",
  //     //     // top: 0,
  //     //     // bottom: 0,
  //     //     // right: 0,
  //     //     // left: 0,
  //     //     width: width > 800 ? '73%' : '100%',
  //     //     zIndex: "9999",
  //     //     height: "100%",
  //     //     alignSelf: 'center',
  //     //     backgroundColor: "#00000099",
  //     //     borderRadius: 10,
  //     //     alignItems: 'center',
  //     //     opacity: !showRedeem ? "0" : "1",
  //     //     transition: "all .2s",
  //     //     visibility: !showRedeem ? "hidden" : "visible",
  //     //   }}
  //     // >

  //     // </div >
  //   );
  // };
  console.log(data, 'mmmmmmmmmmmms')

  return (<>
    <div style={{
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <BranchPopup item={data} show={showBranches} />
    </div>
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <div
        style={{
          width: width < 800 ? "100%" : "80%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            width: "90%",
            display: "flex",
            alignSelf: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ zIndex: 1000 }}>
            <Header heading="Offers" phone showback profile noti heart />
          </div>
          <img
            src={isFave ? RedHeart : Heart}
            alt=""
            style={{
              width: "30px",
              zIndex: "15",
            }}
            onClick={handleHeartClick}
          />
        </div>
        <div
          style={{ width: width < 800 ? "100%" : "90%", alignSelf: "center" }}
        >
          <div style={{ height: width < 800 ? "30vh" : "40vh" }}>
            <img
              src={data.header}
              class="zoom-in-out-box"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "20px 20px 0 0",
              }}
            />
            <div
              style={{
                position: "absolute",
                margin: "auto",
                left: 0,
                right: 0,
                zIndex: 999,
                marginTop: -94,
                background: "#2f2f30e6",
                width: "110px",
                height: "50px",
                borderRadius: "20px 20px 0 0",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <a href={"tel:" + data.phone}>
                <img src="/images/call.png" style={{ height: "20px" }} />
              </a>
            </div>
          </div>
        </div>

        <motion.div
          initial={{ y: 300, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          style={{
            display: "flex",
            height: width < 800 ? "65vh" : "70vh",
            width: width < 800 ? "100%" : "90%",
            alignSelf: "center",
            zIndex: 99,
            marginTop: -40,
            borderTopLeftRadius: 50,
            borderTopRightRadius: 50,
            backgroundColor: "white",
            flexDirection: "column",
            // background: showRedeem ? 'url("/images/backdrop.svg"), #dedede' : '',
          }}
        >
          {/* <RedemptionPopup item={data} show={showRedeem} /> */}
          {
            showRedeem ?
              <div
                style={{
                  display: "block",
                  position: "absolute",
                  width: width > 800 ? '73%' : '100%',
                  zIndex: "51",
                  height: "100vh",
                  top: 0,
                  backgroundColor: "#00000099",
                  opacity: "1",
                  transition: "all .2s",
                }}
              ><div
                style={{
                  display: "flex",
                  position: 'fixed',
                  bottom: 0,
                  alignSelf: 'center',
                  justifyContent: "center",
                  height: "60vh",
                  width: width > 800 ? '73%' : '100%',
                  background: 'url("/images/backdrop.svg"), #dedede',
                  borderRadius: "30px 30px 0 0",
                  boxShadow: "0px -5px 20px 10px #59595936",
                }}
              >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      // margin: "20px",
                    }}
                  >
                    <img
                      src={redemptionTab == 3 ? Check : data.logo}
                      style={{
                        width: "90px",
                        height: "90px",
                        borderRadius: "20px",
                        boxShadow: "#5959596b 3px 3px 7px 3px",
                        backgroundColor: "white",
                        padding: "0px",
                      }}
                    />
                    <p
                      className="mt-2"
                      style={{ display: redemptionTab == 3 ? "none" : "initial" }}
                      dangerouslySetInnerHTML={{ __html: selectedVoucher.description }}
                    ></p>
                    {redemptionTab == 1 ? (
                      <div>
                        {selectedVoucher.eligiblity == 0 &&
                          state.user.membership.id == 1 ? (
                          <button
                            className="redeemBtn"
                            onClick={() => (window.location.href = "/activate")}
                          >
                            Activate
                          </button>
                        ) : (
                          <button
                            className="redeemBtn"
                            onClick={() => {
                              location.state.offerType == 4
                                ? confirmAlert(options)
                                : setRedemptionTab(2);
                            }}
                          >
                            Redeem
                          </button>
                        )}
                      </div>
                    ) : redemptionTab == 2 ? (
                      <div>
                        <sub>Please enter service provider PIN</sub>
                        <div className="multi-input">
                          <div>
                            {[...Array(4)].map((_, index) => (
                              <input
                                className="input"
                                type="number"
                                key={index}
                                ref={(input) => (inputs.current[index] = input)}
                                maxLength="1"
                                onChange={(e) => handleChange(e, index)}
                                onKeyUp={(e) => handleKeyUp(e, index)}
                                style={{ marginRight: "8px", width: "32px" }}
                              />
                            ))}
                          </div>
                        </div>{" "}
                      </div>
                    ) : redemptionTab == 3 ? (
                      <div>
                        <br />
                        <b>Success!</b>
                        <br />
                        <b>Your redemption is complete</b>
                        <p style={{ backgroundColor: "#EF5E5E", color: "white" }}>
                          Reference number: <br />
                          <b>{refNum}</b>
                        </p>
                        <p>
                          {" "}
                          Earn <b>1000 points</b> by leaving a rating & review.{" "}
                        </p>
                        <button
                          className="redeemBtn"
                          onClick={() => setShowRedeem(false)}
                        >
                          Done
                        </button>
                      </div>
                    ) : null}

                    <br />
                    <p>
                      Estimated Savings: <b>{selectedVoucher.savings}</b> <br />{" "}
                      Customer Name: <b> {state.user.fname}</b>
                    </p>
                    <p>
                      Offers are subject to{" "}
                      <a href="https://vouch365.com/rules-of-use/">Rules of use</a>{" "}
                    </p>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    <img
                      onClick={() => setShowRedeem(false)}
                      src={dropdown}
                      style={{
                        width: "15px",
                        height: "15px",
                        padding: "10px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </div></div> : <>

                <Heading tab={tab} setTab={setTab} />
                {tab === 1 ? (
                  <OfferList item={data} />
                ) : tab === 2 ? (
                  <Details item={data} />
                ) : (
                  <Reviews item={data} />
                )}
              </>
          }
        </motion.div>
      </div>
    </div>
  </>

  );
}

const Details = ({ item }) => {
  return (
    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column', paddingBottom: "30px", width: '90%', alignSelf: 'center' }}>
      <p
        style={{
          fontSize: "15px",
          fontWeight: "600",
          fontFamily: "sans-serif",
          margin: "0px",
        }}
      >
        {item.city.name.toUpperCase()}
      </p>
      <p
        style={{
          fontSize: "15px",
          fontWeight: "400",
          fontFamily: "sans-serif",
          margin: "5px 0px",
        }}
      >
        {item.address}
      </p>
      <p
        style={{
          fontSize: "15px",
          fontWeight: "600",
          fontFamily: "sans-serif",
          margin: "0px",
          display: "none",
        }}
      >
        Distance: 0 KM
      </p>
      <div>
        <p
          style={{
            fontSize: "15px",
            fontWeight: "500",
            fontFamily: "sans-serif",
            margin: "30px 0px 0px 0px",
          }}
        >
          {" "}
          {item.description}{" "}
        </p>
      </div>
      <div style={{ alignSelf: 'center', marginTop: 10, }}>
        <a
          href={
            "https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=" +
            item.latitude +
            "," +
            item.longitude
          }
        >
          <img
            src="https://miro.medium.com/max/1400/1*FbzQStUzSsLChBJE9108hg.png"
            alt=""
            style={{ width: "100%", borderRadius: 20 }}
          />
        </a>
      </div>
      <p
        style={{
          fontSize: "15px",
          fontWeight: "600",
          fontFamily: "sans-serif",
          margin: "0px",
        }}
      >
        Addtional Details
      </p>
    </div>
  );
};
const Reviews = ({ item }) => {
  const progress = [
    { pro: "5" },
    { pro: "4" },
    { pro: "3" },
    { pro: "2" },
    { pro: "1" },
  ];
  console.log(item, 'mamamamam')
  return (
    <div className='offerList'>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "55%",
            display: "flex",
          }}
        >
          <p
            style={{
              fontSize: "15px",
              fontWeight: "600",
              fontFamily: "sans-serif",
              margin: "0px",
            }}
          >
            Ratings and reviews
          </p>
          <img src={Info} alt="" style={{ width: "20px", marginLeft: 10 }} />
        </div>
        <img src={ArrowRight} alt="" style={{ width: "30px", }} />
      </div>
      <br />
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "40%" }}>
          <h1
            style={{
              margin: 0,
              fontSize: "50px",
              lineHeight: "60px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {item.rating}
          </h1>
          <Rating
            size={17}
            iconsCount={5}
            initialValue={item.rating}
            fillColor="#00885E"
            readonly={true}
          />
          <span
            style={{ fontSize: "10px", fontWeight: "400", textAlign: "left" }}
          >
            {item.ratings_count}
          </span>
        </div>
        <div
          style={{
            width: "100%",
            alignSelf: "center",
            marginLeft: "10px",
            marginBottom: "12px",
          }}
        >
          {progress.map((itm, i) => (
            <div key={i} style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  width: "10px",
                  textAlign: "center",
                }}
              >
                {itm.pro}
              </span>
              <Progress_bar
                bgcolor="#00885E"
                progress={(item.ratings[itm.pro] / item.reviews.length) * 100}
                height={10}
              />
            </div>
          ))}
        </div>
      </div>
      <div>
        <p style={{ fontSize: "16px", fontWeight: "600", color: "gray" }}>
          All Reviews
        </p>
        {item.reviews.length == 0 && (
          <p style={{ fontSize: "16px", fontWeight: "600", color: "gray" }}>
            No Reviews have been given to this vendor
          </p>
        )}
        {item.reviews.map((itm, i) => (
          <div
            key={i}
            style={{
              display: "flex",
              padding: "15px 0",
              alignItems: "center",
              height: "50px",
              borderBottom: "1px solid #ededed",
              borderTop: "1px solid #ededed",
            }}
          >
            <img
              src={(itm && itm.user && itm.user.avatar) ? "https://v3beta.vouch365.mobi/" + itm.user.avatar : ''}
              alt=""
              style={{
                width: "40px",
                borderRadius: "20px",
                marginRight: "10px",
                height: "42px",
              }}
            />
            <div>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  margin: "10px 0px 0px 0px ",
                }}
              >
                {itm?.user?.fname}
              </p>
              <div
                style={{ display: "flex", height: "42px", margin: "3px 0 0" }}
              >
                <p
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    margin: 0,
                    color: "gray",
                  }}
                >
                  Rated
                </p>
                <Rating
                  style={{
                    fontSize: "0px",
                    marginTop: "-10px",
                    marginLeft: "5px",
                  }}
                  size={17}
                  iconsCount={5}
                  initialValue={itm.rating}
                  readonly={true}
                />
              </div>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  marginTop: "-20px",
                  color: "gray",
                }}
              >
                {itm.feedback}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Offers;

//  <>
//    <div
//      style={{
//        padding: "0px 10px",
//        position: "fixed",
//        top: 0,
//        left: 0,
//        width: "100%",
//        height: "65px",
//        zIndex: "10",
//        background: "white",
//      }}
//    ></div>
//    <div style={myStyle}>
//      <RedemptionPopup item={data} show={showRedeem} />
//      <BranchPopup item={data} show={showBranches} />

//      <img
//        src={data.header}
//        style={{
//          width: "100%",
//          height: "50%",
//          objectFit: "cover",
//          borderRadius: "20px 20px 0 0",
//          position: "fixed",
//          top: "65px",
//        }}
//      />
//      <img
//        src={isFave ? RedHeart : Heart}
//        alt=""
//        style={{
//          position: "fixed",
//          top: "15px",
//          right: "10px",
//          width: "30px",
//          zIndex: "15",
//        }}
//        onClick={handleHeartClick}
//      />
//      <div
//        style={{
//          position: "absolute",
//          height: "60vh",
//          width: "100%",
//          backgroundColor: "white",
//          bottom: "0px",
//          borderTopLeftRadius: "25px",
//          borderTopRightRadius: "25px",
//          padding: "0px",
//          boxShadow: "0px -5px 20px 10px #59595936",
//        }}
//      >
//        <div
//          style={{
//            position: "absolute",
//            margin: "auto",
//            left: 0,
//            right: 0,
//            top: -50,
//            background: "#2f2f30e6",
//            width: "110px",
//            height: "50px",
//            borderRadius: "20px 20px 0 0",
//            display: "flex",
//            justifyContent: "space-around",
//            alignItems: "center",
//          }}
//        >
//          <a href={"tel:" + data.phone}>
//            <img src="/images/call.png" style={{ height: "20px" }} />
//          </a>
//        </div>
//        <div
//          style={{
//            padding: "10px",
//            background: "white",
//            borderRadius: "25px",
//          }}
//        >
//          <Heading tab={tab} setTab={setTab} />
//          <div
//            style={{
//              height: "70vh",
//              width: "100%",
//              backgroundColor: "white",
//              bottom: "0px",
//              borderTopLeftRadius: "25px",
//              borderTopRightRadius: "25px",
//              padding: "0px",
//              overflow: "scroll",
//              marginTop: "20px",
//            }}
//          >
//            {tab === 1 ? (
//              <OfferList item={data} />
//            ) : tab === 2 ? (
//              <Details item={data} />
//            ) : (
//              <Reviews item={data} />
//            )}
//          </div>
//        </div>
//      </div>
//    </div>
//  </>;
