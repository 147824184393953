import Branding from "../../components/HomeComponents/Brands";
import Features from "../../components/HomeComponents/Featuers";
import Header from "../../components/HomeComponents/Header";
import HeaderOptions from "../../components/HomeComponents/HeaderOptions";
import HeaderSearch from "../../components/HomeComponents/HeaderSearch";
import Trending from "../../components/HomeComponents/Trending";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Footer from "../../components/Footer";
import Suggestions from "../../components/HomeComponents/Suggestions";
import { motion } from "framer-motion"
const HomeScreen = () => {
  const { width } = useWindowDimensions();

  return (
    <motion.div
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 1 }}
      className="HomeDivMain">
      <Header />
      <HeaderOptions />
      <HeaderSearch img />
      <Trending />
      <br />
      <h4 style={{ color: "#686C6E", width: "90%", alignSelf: "center" }}>
        Features
      </h4>
      <Features />
      <br />
      <Suggestions />
      {/* <br /> */}
      <h4
        style={{
          marginBottom: 0,
          color: "#686C6E",
          width: "90%",
          alignSelf: "center",
        }}
      >
        New Brands
      </h4>
      <Branding />
      <br />
      <br />
      {false && <Footer />}
    </motion.div>
  );
};

export default HomeScreen;
