import "./index.css";
import React from "react";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import { useContext, useState } from "react";
import { SessionContext } from "../../components/UserContext";
import { toast } from "react-toastify";
import logo from '../../images/logo.png'

function Login() {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [tab, setTab] = useState(1);
  const { state, updateState } = useContext(SessionContext);
  const [cities, setCities] = React.useState([]);
  const [userData, setUserData] = React.useState({
    email: "",
    password: "",
    gender: "Male",
    fName: "",
    lName: "",
    city: 1,
    dob: "",
    phone: "",
  });
  //const [userData, setUser]= React.useState({password:'', email:''})

  //get URL param to check if Signup is clicked

  async function fetchCities(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // 'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const signup = urlParams.get("signup");
    if (signup === "1") {
      setTab(2);
    }
    fetchCities("https://v3beta.vouch365.mobi/api/cities", {}).then((data) => {
      setCities(data.data);
    });
  }, []);

  function handleClick(e) {
    e.preventDefault();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };
    const login = "login/vouch365";
    // cnic: userData.email,
    const data = {
      phone: userData.password,
    };
    axios
      .post(login, data, headers)
      .then((response) => {
        if (response.data.status === true) {
          var usrInfo = response.data.data.user;
          var constants = response.data.data.user.appConstants;
          toast.success("You have successfully logged in!");
          updateState({
            user: usrInfo,
            loggedIn: true,
            token: response.data.data.token,
            appConstants: constants,
          });
          navigate("/");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.errors.message);
        // toast.error("Error! " + error);
        console.log(error, 'aaadad')
        // console.error("There was an error!", error);
      });
  }

  function handleSignup(e) {
    e.preventDefault();
    if (!isChecked) return;
    const login = "register/vouch365";
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const data = {
      email: userData.email,
      password: userData.password,
      lname: userData.lName,
      fname: userData.fName,
      //dob: userData.dob,
      city_id: userData.city,
      gender: userData.gender,
      phone: userData.phone,
    };
    //console.log(data);
    axios
      .post(login, data, headers)
      .then((response) => {
        if (response.data.status === true) {
          toast.success("You have successfully registered!");
          setTab(1);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error("Error! This email address is already registered.");
      });
  }

  const Heading = ({ tab, setTab }) => {
    return (
      <div
        className="headingsTab"
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          height: "30px",
          margin: "10px 0px",
        }}
      >
        <div
          onClick={() => setTab(1)}
          style={{
            margin: "0 20px",
            width: "100%",
            fontSize: "12px",
            fontWeight: "bold",
            margin: 10,
            textAlign: "center",
            borderBottom: tab === 1 ? "2px solid red" : "",
          }}
        >
          <h3>LOGIN</h3>
        </div>
        {false && (
          <div
            onClick={() => setTab(2)}
            style={{
              margin: "0 20px",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
              margin: 10,
              textAlign: "center",
              borderBottom: tab === 2 ? "2px solid red" : "",
            }}
          >
            <h3>SIGNUP</h3>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="model">
        <div className="modelcontent">
          <div className="pic">
            <img src={logo} style={{ width: "30%" }} alt="logo" />
          </div>
          <br />
          <Heading tab={tab} setTab={setTab} />
          {tab === 1 ? (
            <div className="container">
              <form onSubmit={handleClick}>
                {/* <label>CNIC</label>
                <input
                  className="user"
                  type="number"
                  placeholder="CNIC"
                  value={userData.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                />
                <br />
                <br /> */}
                <label>Phone Number</label>
                <br />
                <input
                  className="place"
                  type="number"
                  placeholder="Phone Number"
                  value={userData.password}
                  onChange={(e) =>
                    setUserData({ ...userData, password: e.target.value })
                  }
                />
                <br />
                <br />
                <button className="login-btn">LOGIN</button>
                <br />
                {false && (
                  <p
                    className="text-center"
                    onClick={() => setTab(2)}
                    style={{ color: "#d00027" }}
                  >
                    Create an account
                  </p>
                )}
              </form>
              <br />
            </div>
          ) : (
            <div className="container">
              <form onSubmit={handleSignup}>
                <label>First name</label>
                <input
                  className="user"
                  required
                  type="text"
                  placeholder="First name"
                  value={userData.fName}
                  onChange={(e) =>
                    setUserData({ ...userData, fName: e.target.value })
                  }
                />

                <label>Last name</label>
                <input
                  className="place"
                  required
                  type="text"
                  placeholder="Last name"
                  value={userData.lName}
                  onChange={(e) =>
                    setUserData({ ...userData, lName: e.target.value })
                  }
                />

                <label>Email</label>
                <input
                  className="place"
                  required
                  type="email"
                  placeholder="Email"
                  value={userData.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                />

                <label>Password</label>
                <input
                  className="place"
                  required
                  type="password"
                  minLength={6}
                  placeholder="Password"
                  value={userData.password}
                  onChange={(e) =>
                    setUserData({ ...userData, password: e.target.value })
                  }
                />

                <label>Phone Number</label>
                <input
                  className="place"
                  required
                  type="tel"
                  pattern="0[0-9]{3}[0-9]{7}"
                  minLength={11}
                  maxLength={11}
                  placeholder="Phone number"
                  value={userData.phone}
                  onChange={(e) =>
                    setUserData({ ...userData, phone: e.target.value })
                  }
                />

                <label>Cities</label>
                <br />

                <select
                  required
                  className="select"
                  value={userData.city}
                  onChange={(e) => {
                    setUserData({ ...setUserData, city: e.target.value });
                  }}
                >
                  {cities &&
                    cities.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>

                <label>Gender</label>
                <br />

                <select
                  required
                  className="select"
                  value={userData.gender}
                  onChange={(e) => {
                    setUserData({ ...setUserData, gender: e.target.value });
                  }}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Others</option>
                </select>

                <div style={{ display: "flex", gap: "20px" }}>
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    style={{ color: "red", width: "8%" }}
                    onChange={() => setIsChecked(!isChecked)}
                    checked={isChecked}
                  />
                  <p style={{ padding: 5 }}>
                    I have read the{" "}
                    <a
                      style={{ color: "red" }}
                      target="_blank"
                      href="https://vouch365.com/end-user-license-agreement/"
                    >
                      End User License Agreement{" "}
                    </a>
                    .
                  </p>
                </div>
                <button className="login-btn">SIGNUP</button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
