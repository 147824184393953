import React, { useState } from "react";
import TrendingFooter from "./TrendingFooter";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef } from "react";
import { SessionContext } from "../../components/UserContext";
import axios from "../../utils/axios";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";

const Trending = () => {
  var interval;
  const myRef = useRef()
  const { width } = useWindowDimensions();
  const getMaxScrollLimit = () => {
    const scrollHeight = myRef.current.scrollWidth;
    const clientHeight = myRef.current.clientWidth;
    return scrollHeight - clientHeight;
  };
  const StartAnimation = () => {
    let lastVal = 0
    interval = setInterval(() => {
      const incrementVal = width < 800 ? 300 : 500
      if (myRef.current) {
        const value = getMaxScrollLimit()
        if (value == myRef.current.scrollLeft) {
          myRef.current.scrollTo({
            left: 0,
            behavior: "smooth"
          })
          lastVal = 0
        } else {
          myRef.current.scrollTo({
            left: lastVal + incrementVal,
            behavior: "smooth"
          })
          lastVal += incrementVal
        }
      }
    }, 3000);

  }

  useEffect(() => {
    StartAnimation()
    return () => clearInterval(interval)
  }, [])
  var trendingImages = [];
  var doNavigate = true;
  const placeholder = [
    {
      header:
        "https://mtek3d.com/wp-content/uploads/2018/01/image-placeholder-500x500.jpg",
      id: 35539,
    },
    {
      header:
        "https://mtek3d.com/wp-content/uploads/2018/01/image-placeholder-500x500.jpg",
      id: 35540,
    },
    {
      header:
        "https://mtek3d.com/wp-content/uploads/2018/01/image-placeholder-500x500.jpg",
      id: 35541,
    },
    {
      header:
        "https://mtek3d.com/wp-content/uploads/2018/01/image-placeholder-500x500.jpg",
      id: 35541,
    },
  ];

  const { state, updateState } = useContext(SessionContext);
  const navigate = useNavigate();

  if (state.sliders.length > 0) {
    state.sliders.map((item) => {
      if (item.position == "top") {
        if (item.slides.length === 0) {
          trendingImages = placeholder;
          doNavigate = false;
        } else {
          trendingImages = item.slides;
        }
      }
    });
  }

  React.useEffect(() => {
    var cityId = state.selectedCity.id == -1 ? 1 : state.selectedCity.id;
    var memberId = state.loggedIn ? state.user.membership.id : 2;
    const url =
      "sliders?city_id=" +
      cityId +
      "&category_id=2&slider_type=home&membership_id=" +
      memberId;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${state.token}`,
    };
    axios
      .get(url, { headers: headers })
      .then((response) => {
        //console.log(response);
        updateState({ sliders: response.data.data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  return (
    <>
      <h4
        style={{
          color: "#686C6E",
          marginBottom: 0,
          width: "90%",
          alignSelf: "center",
        }}
      >
        Trending this week
      </h4>
      <div className="TrendingDiv" ref={myRef}>
        {trendingImages.map((item, i) => (
          <a href={`${item.link}`} key={i}>
            <img
              //        key={i}
              src={item.header}
              alt=""
              //      onClick={() => {
              //      if (doNavigate) navigate("/offers", { state: { id: item.link } });
              //  }}
              className="TrendingImages"
            />
          </a>
        ))}
      </div>
      <br />
      <TrendingFooter />
    </>
  );
};

export default Trending;
