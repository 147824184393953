import Header from "../../components/HomeComponents/Header";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";
import Footer from "../../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { SessionContext } from "../../components/UserContext";
import { motion } from 'framer-motion';

function Redemptions() {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState([]);

  const { state } = useContext(SessionContext);

  useEffect(() => {
    setData(state.user.redemptions);
  }, []);

  return (
    <motion.div
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <div
        style={{
          width: width < 800 ? "100%" : "80%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header showback heading={"Redemptions"} phone />
        <div style={{ height: "100%", width: "90%", alignSelf: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: 10,
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {data.map((item) => (
              <div
                className="RedemptionCard"
                style={{
                  width: "100%",
                  height: "100px",
                  borderRadius: "20px",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div
                  // className="vendorCard"
                  style={{
                    width: "100px",
                    height: "100%",
                    borderRadius: "20px 10px 10px 20px",
                  }}
                >
                  <img
                    src={item.vendor_logo}
                    style={{
                      paddingTop: "10px",
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      marginLeft: 20,
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "80%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingLeft: "10px",
                  }}
                >
                  <h5 style={{ margin: 0, fontSize: 15, color: "#686C6E" }}>
                    {item.vendor_name}
                  </h5>
                  <p style={{ margin: 0, fontSize: 12, color: "#686C6E" }}>
                    {item.voucher_name}
                  </p>
                  <h6 style={{ margin: "5px 0" }}>Redemption Date:</h6>
                  <p style={{ margin: 0, fontSize: 12, color: "#686C6E" }}>
                    {item.created_at}
                  </p>
                </div>
                <div
                  style={{
                    width: "20%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    paddingRight: "10px",
                  }}
                >
                  <p style={{ margin: 0, fontSize: 12, color: "#686C6E" }}>
                    Savings
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: 12,
                      color: "#686C6E",
                      fontWeight: "bold",
                    }}
                  >
                    {item.total_savings} Rs
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Redemptions;
