import Footer from "./components/Footer";
import Category from "./pages/FoodListing/Category";
import Redemptions from "./pages/FoodListing/Redemptions";
import Favorites from "./pages/FoodListing/Favorites";
import HomeScreen from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from 'react';
import Filters from "./pages/Filters";
import Offers from "./pages/Offers";
import Login from "./pages/Login/login";
import SignUp from "./pages/SignUp/signup";
import Activate from "./pages/Activate";
import { SessionProvider } from "./components/UserContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Search from "./pages/FoodListing/Search";
import Splash from "./pages/Login/splash";
import Settings from "./pages/User/Settings";
import Shop from "./pages/User/Shop";

export const token = `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjFjYjA3NmJkNzMxM2FhYWJmNmQzNTEyMjQ0YjkxNTQ4MTU3OTNjNjg0YjVmYWM4Y2E1Y2Q3ODM1Yzg3YmUyY2Y4NTZjZGExZDcxZGRjOGQ3In0.eyJhdWQiOiI4IiwianRpIjoiMWNiMDc2YmQ3MzEzYWFhYmY2ZDM1MTIyNDRiOTE1NDgxNTc5M2M2ODRiNWZhYzhjYTVjZDc4MzVjODdiZTJjZjg1NmNkYTFkNzFkZGM4ZDciLCJpYXQiOjE2NzU3MjA0MzMsIm5iZiI6MTY3NTcyMDQzMywiZXhwIjoxNzA3MjU2NDMzLCJzdWIiOiIyODA1ODMiLCJzY29wZXMiOltdfQ.KVFITI_2iFyF4giQ0uLYPMuRCl3nyfJY9YJ668-Xz2qzm_7ZISpbrWVWC3Y9ZTMEFtFgDGceSD0MECCkPql5XSvbsw-Qd8cR2PB4ISuK2cN8i0QcBvWx1uhxFXUoPsIr7q9WyxoQuZ93052FGS7FZw-WkUOfYXFBj2lvLAGu6vJ3Vjf35Hy757Ep89bjUmHSkdT_npSHonMT9v-DeDHSA0lIFke_clOqgJiKHsI8UZlou7wKzoxXnVxfnCN0F0o4KrPIHV71iDnwqgqIpnhVJLZf3xE7khY7uvz_NIqSjXdtdOMAzHxTeFTS4HXJ69CFe6OJgktCLC-5pKjzgZ42QgulfgYIM1Wrm45KfSQi0hTzmHGfDuCYqy1shXxedFTmfri6EKXC1II3pWIVCT1xvNvTz6DOvuGBoMKiY3ph2SKCjo0M1URce_1EesCOGJzxbZrXMtxtV4LOEIuTWDaQQT_dkOLgeIEODUw-ck_7WVCq4KU_-4vF_lmHM78QuGCiVEi7e-zeoEorosBpQbbg2P1RiVgjfLHVeUvDd8kHdwrDx4G62DgGlVizor-V1h4ZvyLZSChL1gq_jbtkv8lfqZCBxkfmbOQNGmM0qFCk_Pfl_tTH7WwKF1vbx_173MToF7uD3UYFuXW7xceDbeXquCyGUJxl22AmS8aeIJgMLYk`;

function App() {

  return (
      <SessionProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/splash" element={<Splash />} />
            <Route path="/login" element={<Login />} />
            <Route path="/category" element={<Category />} />
            <Route path="/redemptions" element={<Redemptions/>} />
            <Route path="/settings" element={<Settings/>} />
            <Route path="/favorites" element={<Favorites/>} />
            <Route path="/" element={<HomeScreen />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/activate" element={<Activate />} />
            <Route path="/search" element={<Search/>} />
            <Route path="/filters" element={<Filters/>} />
            <Route path="/offers" element={<Offers />} />
            <Route path="/shop" element={<Shop />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer position="bottom-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored" />
      </SessionProvider>
  );
}

export default App;
