import React from "react";
import Bell from "../../images/headerBell.svg";
import Heart from "../../images/headerHeart.svg";
import Phone from "../../images/headerPhone.svg";
import Profile from "../../images/headerProfile.svg";
import Back from "../../images/backArrow.svg";
import Cancel from "../../images/cancel.svg";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SessionContext } from "../../components/UserContext";

const Header = ({
  showback,
  heading,
  phone,
  cancel,
  heart,
  profile,
  noti,
  hideOpts,
  backBtnDisabled = false,
}) => {
  const navigate = useNavigate();

  const { state } = useContext(SessionContext);
  //user = JSON.parse(localStorage.getItem('user'));

  function handleLoginClick(e) {
    if (!state.loggedIn) navigate("/login");
  }
  function handleFavoriteClick(e) {
    if (state.loggedIn) navigate("/favorites");
  }
  return (
    <div
      style={{
        display: "flex",
        flexDireaction: "row",
        width: "90%",
        alignSelf: "center",
      }}
    >
      <div
        style={{
          width: hideOpts ? "100%" : "70%",
          display: "flex",
          lineHeight: "30.49px",
        }}
      >
        {!showback && state.loggedIn ? (
          <span>
            <h2
              style={{
                fontWeight: "400",
                fontSize: "30px",
                marginBottom: 0,
                color: "#5f676a",
              }}
            >
              {" "}
              Hello,
            </h2>
            <h2
              style={{
                fontWeight: "800",
                fontSize: "30px",
                margin: "5px 0px 0px 0px",
              }}
            >
              {" "}
              {state.user.fname}
            </h2>
          </span>
        ) : (
          ""
        )}
        {showback ? (
          <img
            src={Back}
            style={{ width: "22px", marginRight: "8px" }}
            alt="logo"
            onClick={() => {
              //console.log(backBtnDisabled);
              if (!backBtnDisabled) navigate(-1);
            }}
          />
        ) : (
          ""
        )}
        {heading ? (
          <h2
            style={{ fontWeight: "400", fontSize: "21.82px", margin: "15px 0" }}
          >
            {" "}
            {heading}
          </h2>
        ) : (
          ""
        )}
        {!showback && !heading && !state.loggedIn ? (
          <span>
            <h2
              style={{
                fontWeight: "400",
                fontSize: "30px",
                marginBottom: 0,
                color: "#5f676a",
              }}
            >
              {" "}
              Hello,
            </h2>
            <h2
              style={{
                fontWeight: "800",
                fontSize: "30px",
                margin: "5px 0px 0px 0px",
              }}
            >
              {" "}
              Vouch365{" "}
            </h2>
          </span>
        ) : (
          ""
        )}
      </div>
      <div
        style={{
          display: hideOpts ? "none" : "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "30%",
        }}
      >
        {cancel ? (
          <img
            src={Cancel}
            style={{ width: "25px", marginRight: "8px" }}
            alt="logo"
            onClick={() => navigate(-1)}
          />
        ) : (
          <>
            {!phone && (
              <a
                href={"tel:+" + state.appConstants.callButtonNumber}
                style={{ height: "25px" }}
              >
                {" "}
                <img
                  src={Phone}
                  style={{ width: "25px", marginRight: "8px" }}
                  alt="logo"
                />{" "}
              </a>
            )}
            {!heart && (
              <img
                src={Heart}
                style={{ width: "25px", marginRight: "8px" }}
                alt="logo"
                onClick={handleFavoriteClick}
              />
            )}
            {!profile && false && (
              <img
                src={Profile}
                style={{ width: "25px", marginRight: "8px" }}
                alt="logo"
                onClick={handleLoginClick}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
