import { React, createContext, useState, useEffect } from "react";

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [state, setState] = useState(() => {
    const sessionState = sessionStorage.getItem("state");
    if (sessionState) {
      return JSON.parse(sessionState);
    } else {
      return {
        loggedIn: false,
        appConstants: {},
        user: {},
        token:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImFkNGEyYzE4NzExYzYyNmU2YWRmOTQ2MWEyNmFmNmFhMWQ1NjA5ZDNkMTZhNzM0NzZjYzBjOTE2MmMxODI2N2FlYjU2NGIwZDdhZDQwYThmIn0.eyJhdWQiOiI4IiwianRpIjoiYWQ0YTJjMTg3MTFjNjI2ZTZhZGY5NDYxYTI2YWY2YWExZDU2MDlkM2QxNmE3MzQ3NmNjMGM5MTYyYzE4MjY3YWViNTY0YjBkN2FkNDBhOGYiLCJpYXQiOjE2Nzc0OTYyMDYsIm5iZiI6MTY3NzQ5NjIwNiwiZXhwIjoxNzA5MDMyMjA2LCJzdWIiOiIxMzU4Iiwic2NvcGVzIjpbXX0.UBG_0ytHdZpShnjuqa-vFIrWtz3Z-U06EJICxW2ml1vIxfjODe7qcPArz3biAGG24DOa5C0WrlTKuzFNSlT9PB51siX-c0KJ21c2KtzAaU_qs4m952Qt64wiNIZ6BhPK60d7ZszFeJsGdrLdtBsf-rZfb8WBhOjSa9rMJ4NnpDqZt40eXKosDEjavqWdVdWXiKmXUbSgUfCGitAk0Ad-Lc3TXpaQm9Na0zdBdDyEKu2R9_hNuyFuPDM6gANZcq2wNVJsmysBVw4wu_KREak6uRBcGTlyTvlf-QiZj5oPZ8U6Ur7oflvimuhU7tBa9idKrDKtty0isFBFHfB2Jq01XcZZBQj9RC2AWrXAbB-yOrCKYANPpu1cyuRc96JjblF0TRxk7_BeJXGgdUtSgGIp6guMnq4SJemou9BcLljJt-v_AGNXf3ADk2tUcI2vtuIiUtdYsIPgWCzk5MMbnvwrQiIzkEI4kvF9wpPRDElBeMgt09o5_-VPbTHmAmMnYepE-a0_L4MJPLC-k3EGu9MiCXqX3QM6VDJCDG2Y2aueMNYR8M8MqGSffbIfrOt1-_cJUIZvxNjousDDbz4CIK0_fstMYbOaJ8gtV-8Ge1H_1ZFhrL_rgeBtnxp8sJgKqb7BvBThPx_D4JTi2F0Xul7Zf5ex85jomMkJwlMP4s200K8",
        rewards: [],
        cities: [],
        selectedCity: { id: -1, value: "", label: "" },
        categories: [],
        sliders: [],
        favorites: [],
      };
    }
  });

  useEffect(() => {
    sessionStorage.setItem("state", JSON.stringify(state));
  }, [state]);

  const updateState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const clearState = () => {
    setState({
      loggedIn: false,
      appConstants: {},
      user: {},
      token:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImFkNGEyYzE4NzExYzYyNmU2YWRmOTQ2MWEyNmFmNmFhMWQ1NjA5ZDNkMTZhNzM0NzZjYzBjOTE2MmMxODI2N2FlYjU2NGIwZDdhZDQwYThmIn0.eyJhdWQiOiI4IiwianRpIjoiYWQ0YTJjMTg3MTFjNjI2ZTZhZGY5NDYxYTI2YWY2YWExZDU2MDlkM2QxNmE3MzQ3NmNjMGM5MTYyYzE4MjY3YWViNTY0YjBkN2FkNDBhOGYiLCJpYXQiOjE2Nzc0OTYyMDYsIm5iZiI6MTY3NzQ5NjIwNiwiZXhwIjoxNzA5MDMyMjA2LCJzdWIiOiIxMzU4Iiwic2NvcGVzIjpbXX0.UBG_0ytHdZpShnjuqa-vFIrWtz3Z-U06EJICxW2ml1vIxfjODe7qcPArz3biAGG24DOa5C0WrlTKuzFNSlT9PB51siX-c0KJ21c2KtzAaU_qs4m952Qt64wiNIZ6BhPK60d7ZszFeJsGdrLdtBsf-rZfb8WBhOjSa9rMJ4NnpDqZt40eXKosDEjavqWdVdWXiKmXUbSgUfCGitAk0Ad-Lc3TXpaQm9Na0zdBdDyEKu2R9_hNuyFuPDM6gANZcq2wNVJsmysBVw4wu_KREak6uRBcGTlyTvlf-QiZj5oPZ8U6Ur7oflvimuhU7tBa9idKrDKtty0isFBFHfB2Jq01XcZZBQj9RC2AWrXAbB-yOrCKYANPpu1cyuRc96JjblF0TRxk7_BeJXGgdUtSgGIp6guMnq4SJemou9BcLljJt-v_AGNXf3ADk2tUcI2vtuIiUtdYsIPgWCzk5MMbnvwrQiIzkEI4kvF9wpPRDElBeMgt09o5_-VPbTHmAmMnYepE-a0_L4MJPLC-k3EGu9MiCXqX3QM6VDJCDG2Y2aueMNYR8M8MqGSffbIfrOt1-_cJUIZvxNjousDDbz4CIK0_fstMYbOaJ8gtV-8Ge1H_1ZFhrL_rgeBtnxp8sJgKqb7BvBThPx_D4JTi2F0Xul7Zf5ex85jomMkJwlMP4s200K8",
      rewards: [],
      cities: [],
      selectedCity: { id: -1, value: "", label: "" },
      categories: [],
      sliders: [],
      favorites: [],
    });
  };

  if (!state.loggedIn) {
    if (
      !window.location.pathname.includes("login") &&
      !window.location.pathname.includes("splash")
    )
      window.location.href = "/splash";
  }

  return (
    <SessionContext.Provider value={{ state, updateState, clearState }}>
      <div id="loader" className="loading-bar"></div>
      {children}
    </SessionContext.Provider>
  );
};
