import React from "react";
import { useNavigate } from "react-router-dom";
import redemptions from "../../images/redemptions.svg";
import cancel from "../../images/cancel.svg";
import upgrade from "../../images/upgrade.svg";
import points from "../../images/points.svg";
import savings from "../../images/savings.svg";
import { useContext } from "react";
import { SessionContext } from "../UserContext";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";

const Features = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { state } = useContext(SessionContext);
  const [showSavings, setShowSavings] = React.useState(false);
  const SavingsPopup = () => {
    return (
      //return a div that is fixed position to the screen and shows in the middle with background image whatsapp.png
      <div
        onClick={() => setShowSavings(false)}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 99,
        }}
      >
        <div
          style={{
            background: "url(/images/savings.png) no-repeat",
            backgroundSize: "contain",
            width: "300px",
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "red",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "25px",
          }}
        >
          <p>
            {" "}
            <br />
            PKR
            <br />
            {state.user.savings}
          </p>
        </div>
      </div>
    );
  };

  const [showPoints, setShowPoints] = React.useState(false);
  const PointsPopup = () => {
    return (
      //return a div that is fixed position to the screen and shows in the middle with background image whatsapp.png
      <div
        onClick={() => setShowPoints(false)}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 99,
        }}
      >
        <div
          style={{
            background: "white",
            borderRadius: "20px",
            width: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "black",
            textAlign: "center",
            padding: "10px",
          }}
        >
          <img src={cancel} style={{ marginLeft: "90%", width: "30px" }} />
          <h3>Vouch Points</h3>
          <p>
            {" "}
            Spend your vouch points on making your Vouch365 even more awesome.
            Offers come and go so be sure to check back regularly{" "}
          </p>
          <div
            style={{
              display: "flex",
              width: "80%",
              margin: "10px 0",
              padding: "0 20px",
              backgroundColor: "#e3e3e3",
              borderRadius: "10px",
              justifyContent: "space-between",
            }}
          >
            <p>Vouch Points Available</p>
            <p>{state.user.a_vpoints}</p>
          </div>
          <div
            style={{
              display: "flex",
              width: "80%",
              margin: "10px 0",
              padding: "0 20px",
              backgroundColor: "#e3e3e3",
              borderRadius: "10px",
              justifyContent: "space-between",
            }}
          >
            <p>Vouch Points Used</p>
            <p>{state.user.u_vpoints}</p>
          </div>
          <div
            style={{
              display: "flex",
              width: "80%",
              margin: "10px 0",
              padding: "0 20px",
              backgroundColor: "#e3e3e3",
              borderRadius: "10px",
              justifyContent: "space-between",
            }}
          >
            <p>Redeemed</p>
            <p>{state.user.redemption_count}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {showSavings && <SavingsPopup />}
      {showPoints && <PointsPopup />}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          width: "90%",
          alignSelf: "center",
          marginTop: -10,
          columnGap: width > 800 ? "1.5%" : "3.5%",
        }}
      >
        <div className="featuresItem" onClick={() => setShowSavings(true)}>
          <img
            src={savings}
            alt=""
            style={{ width: width > 800 ? "30px" : "5vw" }}
          />
          <p
            style={{
              marginTop: 5,
              fontSize: width > 800 ? "10px" : "2vw",
              color: "white",
            }}
          >
            {" "}
            My Savings{" "}
          </p>
        </div>
        <div
          className="featuresItem"
          onClick={() => state.loggedIn && navigate("/redemptions")}
        >
          <img
            src={redemptions}
            alt=""
            style={{ width: width > 800 ? "30px" : "5vw" }}
          />
          <p
            style={{
              marginTop: 5,
              fontSize: width > 800 ? "10px" : "2vw",
              color: "white",
            }}
          >
            {" "}
            Redemptions{" "}
          </p>
        </div>
        <div className="featuresItem" onClick={() => setShowPoints(true)}>
          <img
            src={points}
            alt=""
            style={{ width: width > 800 ? "30px" : "5vw" }}
          />
          <p
            style={{
              marginTop: 5,
              fontSize: width > 800 ? "10px" : "2vw",
              color: "white",
            }}
          >
            {" "}
            Points{" "}
          </p>
        </div>
        <div
          className="featuresItem"
          onClick={() => {
            window.location.href = "/shop";
          }}
        >
          <img
            src={upgrade}
            alt=""
            style={{ width: width > 800 ? "30px" : "5vw" }}
          />
          <p
            style={{
              marginTop: 5,
              fontSize: width > 800 ? "10px" : "2vw",
              color: "white",
            }}
          >
            {" "}
            Upgrade{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default Features;
