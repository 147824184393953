import React from "react";
import Search from "../../images/Search.svg";
import Map from "../../images/map.svg";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import axios from "../../utils/axios";
import { SessionContext } from "../UserContext";

const HeaderSearch = ({ sboxVal, img, dropDown, HandleSearch, viewMap }) => {
  const [options, setOptions] = useState([
    { id: -1, value: "Select City", label: "Select City" },
  ]);
  const { state, updateState } = useContext(SessionContext);
  const [defaultId, setDefault] = useState("Karachi");
  const navigate = useNavigate();

  useEffect(() => {
    if (state.cities.length > 0) {
      var itm = [];
      state.cities.map((item) => {
        //captilize first letter of item.name
        var lbl = item.name;
        if (item.name.length > 0)
          lbl = item.name[0].toUpperCase() + item.name.slice(1);

        itm.push({ id: item.id, value: item.name, label: lbl });
      });
      if (state.selectedCity.id != -1) {
        setDefault(state.selectedCity.value);
      }
      setOptions(itm);
    } else {
      const vendor = `cities`;
      axios
        .get(vendor)
        .then((response) => {
          var cities = response.data.data;
          var itm = [];
          cities.map((item) => {
            var lbl = item.name;
            if (item.name.length > 0)
              lbl = item.name[0].toUpperCase() + item.name.slice(1);

            itm.push({ id: item.id, value: item.id, label: lbl });
          });
          updateState({ cities: cities });
          setOptions(itm);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
    if (sboxVal) document.getElementById("searchBox").value = sboxVal;
  }, []);
  const dropdownStyles = {
    fontSize: "12px",
    backgroundColor: "white",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  };
  return (
    <>
      <div
        style={{
          width: "90%",
          alignSelf: "center",
          marginTop: "10px",
          display: "flex",
          flexDireaction: "row",
          alignItems: "center",
          padding: "0px 0px",
        }}
      >
        <div style={{ width: !dropDown ? "60%" : "100%", display: "flex" }}>
          <img src={Search} alt="" style={{ width: "20.5px" }} />
          <input
            id="searchBox"
            onChange={HandleSearch ?? HandleSearch}
            onKeyDown={(e) => {
              if (e.key == "Enter")
                navigate("/search", { state: { search: e.target.value } });
            }}
            className="input"
            style={{
              border: "none",
              width: "100%",
              padding: "10px 15px",
              marginLeft: "10px",
              alignSelf: "center",
            }}
            placeholder="Search..."
          />
        </div>
        {!img || !dropDown ? (
          <div
            style={{
              width: "40%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div style={dropdownStyles}>
              <Dropdown
                options={options}
                onChange={(e) => {
                  options.map((item) => {
                    if (item.value == e.value) {
                      //console.log(item);
                      updateState({ selectedCity: item });
                    }
                  });
                  window.location.reload();
                }}
                value={defaultId}
                placeholder="Select an option"
                valueRenderer="abc"
              />
            </div>
            {!img ? (
              <div onClick={() => viewMap()}>
                {" "}
                <img
                  src={Map}
                  alt=""
                  style={{ height: "28.5px", width: "28.5px" }}
                />{" "}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <hr
        className="solid"
        style={{
          width: "90%",
          alignSelf: "center",
          margin: 0,
        }}
      />
    </>
  );
};

export default HeaderSearch;
