import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import FoodCard from "./FoodCard";
import "./style.css";
import { useContext, useEffect, useRef } from "react";
import { SessionContext } from "../../components/UserContext";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";

const Branding = () => {
  var interval;
  const myRef = useRef()
  const { width } = useWindowDimensions();
  const getMaxScrollLimit = () => {
    const scrollHeight = myRef.current.scrollWidth;
    const clientHeight = myRef.current.clientWidth;
    return scrollHeight - clientHeight;
  };
  const StartAnimation = () => {
    let lastVal = 0
    interval = setInterval(() => {
      const incrementVal = width < 800 ? 300 : 500
      if (myRef.current) {
        const value = getMaxScrollLimit()
        if (value == myRef.current.scrollLeft) {
          myRef.current.scrollTo({
            left: 0,
            behavior: "smooth"
          })
          lastVal = 0
        } else {
          myRef.current.scrollTo({
            left: lastVal + incrementVal,
            behavior: "smooth"
          })
          lastVal += incrementVal
        }
      }
    }, 3000);

  }

  // useEffect(() => {
  //   StartAnimation()
  //   return () => clearInterval(interval)
  // }, [])

  var trendingImages = [];
  const { state } = useContext(SessionContext);
  const navigate = useNavigate();

  if (state.sliders.length > 0) {
    state.sliders.map((item) => {
      if (item.position == "featured") {
        item.slides.map((itx) => {
          trendingImages.push({
            logo: itx.logo,
            header: itx.logo,
            name: itx.tag,
            locality: "",
            link: itx.link,
          });
        });
      }
    });
  }

  return (
    <>
      <div className="BrandsScrolldiv" ref={myRef}>
        {trendingImages.map((item, index) => (
          <a
            key={index}
            style={{ marginRight: "10px" }}
            onClick={() =>
              navigate("/offers", {
                state: { id: item.link },
              })
            }
          >
            <FoodCard item={item} />
          </a>
        ))}

      </div>
    </>
  );
};

export default Branding;
