import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./style.css";
import { useContext, useEffect, useRef } from "react";
import { SessionContext } from "../../components/UserContext";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";

const Suggestions = () => {
  var interval;
  var trendingImages = [];
  const myRef = useRef()
  const { width } = useWindowDimensions();

  const { state } = useContext(SessionContext);
  const navigate = useNavigate();

  if (state.sliders.length > 0) {
    state.sliders.map((item) => {
      if (item.position == "bottom") {
        trendingImages = item.slides;
      }
    });
  }
  const getMaxScrollLimit = () => {
    const scrollHeight = myRef.current.scrollWidth;
    const clientHeight = myRef.current.clientWidth;
    return scrollHeight - clientHeight;
  };
  const StartAnimation = () => {
    let lastVal = 0
    interval = setInterval(() => {
      const incrementVal = width < 800 ? 300 : 500
      if (myRef.current) {
        const value = getMaxScrollLimit()
        if (value == myRef.current.scrollLeft) {
          myRef.current.scrollTo({
            left: 0,
            behavior: "smooth"
          })
          lastVal = 0
        } else {
          myRef.current.scrollTo({
            left: lastVal + incrementVal,
            behavior: "smooth"
          })
          lastVal += incrementVal
        }
      }
    }, 3000);

  }

  useEffect(() => {
    StartAnimation()
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <h4
        style={{
          color: "#686C6E",
          marginBottom: 10,
          width: "90%",
          alignSelf: "center",
        }}
      >
        Suggestions for you
      </h4>
      {/* <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}> */}
      <div className="SuggestionScrolldiv"
        ref={myRef}>
        {trendingImages.map((item, i) => (
          <div
            key={i}
            style={{ marginRight: width < 800 ? "9%" : "20px" }}
            onClick={() => {
              item.linktype == "vendor"
                ? navigate("/offers", { state: { id: item.link } })
                : (window.location.href = item.link);
            }}
          >
            <img src={item.header} alt="" className="SuggestionImages" />
          </div>
        ))}
      </div>
      {/* </Carousel> */}
    </>
  );
};

export default Suggestions;
