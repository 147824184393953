import "../../index.css";
import React, { useState } from "react";
import Header from "../../components/HomeComponents/Header";
import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import { useContext } from "react";
import { SessionContext } from "../../components/UserContext";
import { toast } from "react-toastify";

function Activate() {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [disableBack, setDisableBack] = useState(false);
  const { state, updateState } = useContext(SessionContext);

  const [memKey, setKey] = React.useState("");
  function handleClick(e) {
    e.preventDefault();

    setDisableBack(true);
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${state.token}`,
    };
    //console.log(headers);
    const url = "membership/create";
    const data = { membership_key: memKey };
    axios
      .post(url, data, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status === true) {
          axios
            .get("user", { headers: headers })
            .then((resp) => {
              setDisableBack(false);
              toast.success("You have successfully upgraded your membership!");
              var usrInfo = resp.data.data;
              var constants = resp.data.data.appConstants;
              updateState({ user: usrInfo, appConstants: constants });
              setTimeout(function () {
                navigate("/");
              }, 50);
            })
            .catch((error) => {
              setDisableBack(false);
              toast.error("Error in user API - " + error);
              console.error("There was an error!", error);
            });
        } else {
          setDisableBack(false);
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setDisableBack(false);
        toast.error("Error! Key invalid or already used");
        console.error("There was an error!", error);
      });
  }
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <div
        style={{
          width: width < 800 ? "100%" : "80%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            backgroundColor: "#e6e6e6",
            height: "100vh",
          }}
        >
          <div
            style={{
              padding: "0px 10px",
              width: "100%",
              height: "65px",
              zIndex: "10",
              background: "white",
              borderBottom: "1px solid #bebebe",
            }}
          >
            <Header
              heading="Membership Registration"
              phone
              showback
              profile
              noti
              heart
              hideOpts={true}
              backBtnDisabled={disableBack}
            />
          </div>
          <br />
          <div
            className="container"
            style={{
              color: "black",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <form onSubmit={handleClick}>
              <label>Membership key</label>
              <input
                style={{ fontSize: "16px" }}
                id="memkey"
                name="memkey"
                className="user"
                type="text"
                placeholder="Enter Membership Key"
                value={memKey}
                onChange={(e) => setKey(e.target.value)}
              />
              <br />
              <br />
              <button className="log" style={{ backgroundColor: "#d00027" }}>
                REGISTER
              </button>
            </form>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Activate;
