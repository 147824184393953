// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page * {
  margin: 0;
}
.page .card {
  width: -webkit-fill-available;
  box-shadow: 0px 0px 10px 0px #0000001a;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: white;
}
.page .card .row {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  color: gray;
}
.page .card > .row:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.page .card .row > :first-child {
  flex-basis: 80%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/User/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;AACA;EACE,6BAA6B;EAC7B,sCAAsC;EACtC,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,eAAe;AACjB","sourcesContent":[".page * {\r\n  margin: 0;\r\n}\r\n.page .card {\r\n  width: -webkit-fill-available;\r\n  box-shadow: 0px 0px 10px 0px #0000001a;\r\n  padding: 10px 20px;\r\n  border-radius: 10px;\r\n  background-color: white;\r\n}\r\n.page .card .row {\r\n  padding: 20px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  flex-direction: row;\r\n  align-items: flex-start;\r\n  color: gray;\r\n}\r\n.page .card > .row:not(:last-child) {\r\n  border-bottom: 1px solid #ccc;\r\n}\r\n.page .card .row > :first-child {\r\n  flex-basis: 80%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
